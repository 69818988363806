import { GetServerSideProps } from 'next';
import { useEffect, useState, FormEvent } from 'react';
import { useRouter } from 'next/router';
import cc from 'classcat';
import cookie from 'js-cookie';
import Head from 'next/head';
import Link from 'next/link';

import { COOKIES, MULTIPASS_URL } from '@ov-id/auth/constants';
import { clearCookies, serverSideNoAuth } from '../../libs/user';
import { COOKIE_DOMAIN, IS_PRODUCTION } from '../../config/constants';
import { FC } from '../../types';
import { getLayout } from '../../components/LayoutUnauthenticated';
import { setAccessToken } from '../../libs/accessToken';
import { useStorefrontAccessTokenMutation } from '../../generated/storefront-graphql';
import { useUserLoginMutation, useUserSetPasswordWithAccessTokenMutation } from '../../generated/graphql';
import css from './index.module.scss';
import Form from '../../components/Form';
import listenToClose from '../../utils/listenToClose';
import SocialSignOn from '../../components/SocialSignOn';

type Props = {
  modal?: boolean;
  redirectTo?: string;
};

const Login: FC<Props> = ({ modal = false, redirectTo }) => {
  const router = useRouter();
  const [mounted, setMounted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [user, setUser] = useState({
    email: '',
    password: '',
    recaptchaToken: '',
  });
  // Value also stored in the mutation
  const showCaptchaAt = 5;
  const requireCaptcha = loginAttempts >= showCaptchaAt;
  const redirectURL = redirectTo ? `${MULTIPASS_URL}?redirectTo=${redirectTo}` : MULTIPASS_URL;

  const onChange = ({ target: { name, value } }) => {
    setUser({
      ...user,
      [name]: value,
    });
  };

  const onCaptchaChange = (value: any) => {
    setUser({
      ...user,
      recaptchaToken: value,
    });
  };
  const fieldsets = [
    {
      key: 'email',
      fields: [
        {
          autoComplete: 'username',
          label: 'Email',
          name: 'email',
          onChange,
          required: true,
          type: 'email',
          value: user.email,
        },
      ],
    },
    {
      key: 'password',
      fields: [
        {
          autoComplete: 'current-password',
          label: 'Password',
          name: 'password',
          onChange,
          required: true,
          type: 'password',
          value: user.password,
          link: (
            <Link href={`/users/forgot-password?email=${user.email}${modal ? '&modal=1' : ''}`}>
              <a>Forgot Password?</a>
            </Link>
          ),
        },
      ],
    },
  ];

  if (requireCaptcha) {
    fieldsets.push({
      key: 'recaptcha',
      fields: [
        {
          autoComplete: '',
          label: 'Are you a robot?',
          name: 'recaptcha',
          type: 'captcha',
          onChange: onCaptchaChange,
          value: '',
          required: false,
        },
      ],
    });
  }

  const [login, { client }] = useUserLoginMutation();
  const [createAccessToken] = useStorefrontAccessTokenMutation();
  const [setPasswordWithAccessToken] = useUserSetPasswordWithAccessTokenMutation();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const recaptchaError = 'Please fill out the reCAPTCHA field.';

    if (requireCaptcha && user.recaptchaToken === '') {
      setError(recaptchaError);
      return;
    } else {
      setError('');
    }
    setLoginAttempts(loginAttempts + 1);
    setSubmitting(true);
    setSubmitted(false);

    const {
      data: { userLogin },
    } = await login({
      variables: user,
    });
    setSubmitting(false);
    setSubmitted(true);

    if (userLogin?.invalidRecaptcha) {
      setLoginAttempts(showCaptchaAt);
      setError(recaptchaError);
      return;
    }

    if (userLogin?.accessToken) {
      await client.resetStore();
      setAccessToken(userLogin?.accessToken);
      router.replace(redirectURL);
      return;
    }

    if (!userLogin?.user || userLogin?.hasPassword === false) {
      const { email, password } = user;
      // router.replace('/users/no-password');
      const {
        data: {
          customerAccessTokenCreate: { customerAccessToken, customerUserErrors },
        },
      } = await createAccessToken({
        variables: {
          input: {
            email,
            password,
          },
        },
        context: { client: 'shopify' },
      });

      if (customerAccessToken) {
        const {
          data: { userSetPasswordWithAccessToken },
        } = await setPasswordWithAccessToken({
          variables: { input: { token: customerAccessToken.accessToken, email, password } },
        });

        if (userSetPasswordWithAccessToken?.accessToken) {
          await client.resetStore();
          setAccessToken(userSetPasswordWithAccessToken?.accessToken);
          router.replace(redirectURL);
          return;
        }
        return;
      } else {
        console.error('customerUserErrors', customerUserErrors);
      }
    }

    if (userLogin?.hasPassword === false) {
      setError(
        `The account with that email has not been activated yet or does not have a password.
        Please use the "Forgot Password" link above to set a password.`,
      );
      return;
    }

    if (userLogin?.isVerified === false) {
      router.replace('/users/login-verifying');
      return;
    }

    setError('No active user found with those credentials.');
  };

  useEffect(() => {
    clearCookies();
    setMounted(true);
  }, []);
  useEffect(() => {
    if (!redirectTo) return;

    const now = new Date();
    const expires = new Date();
    expires.setTime(now.getTime() + 5 * 60 * 1000);
    cookie.set(COOKIES.REDIRECT, String(redirectTo), {
      domain: COOKIE_DOMAIN,
      expires,
      secure: IS_PRODUCTION,
    });
  }, [redirectTo]);

  listenToClose(modal);

  return (
    <>
      <Head>
        <title key="title">Log In - Outdoor Voices</title>
        <link
          key="google-fonts"
          href="https://fonts.googleapis.com/css?family=Roboto:500&amp;display=swap"
          rel="stylesheet"
        />
      </Head>
      <div className={css.root}>
        <h1 className={cc([{ [css.modalTitle]: modal }])}>Log In</h1>
        {modal && <p className={css.modalCopy}>Log in to access your account or add items to your wishlist.</p>}
        <Form
          error={error}
          fieldsets={fieldsets}
          onSubmit={onSubmit}
          submitADA="Click To Log In"
          submitLabel="Log In"
          submitted={submitted}
          submitting={submitting}
        />
        <noscript>
          <p className={css.alert}>This site requires JavaScript to work. Please enable it in your browser to login.</p>
        </noscript>
        {mounted && window.navigator && !window.navigator.cookieEnabled && (
          <p className={css.alert}>This site requires cookies to log in. Please enable them in your browser to login</p>
        )}
        <p>
          Don&apos;t have an account?{' '}
          <Link href={`/users/register${modal ? '?modal=1' : ''}`}>
            <a>Create Account</a>
          </Link>
        </p>
        <SocialSignOn title="Or Log In With Social" />
      </div>
    </>
  );
};
Login.getLayout = getLayout;
export const getServerSideProps: GetServerSideProps = async (context) => {
  const { modal, redirectTo = null } = context.query;
  await serverSideNoAuth(context);
  return {
    props: {
      modal: modal === '1',
      redirectTo,
    },
  };
};
export default Login;
