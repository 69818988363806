import { useEffect } from 'react';

import { IS_PRODUCTION, KEY_CODES, KEY_DOWN, SHOPIFY_URL } from '../config/constants';

const listenToClose = (modal: boolean): void => {
  if (!modal) {
    return;
  }

  useEffect(() => {
    const onKeyDown = (event: { key: string }) => {
      if (event.key === KEY_CODES.ESC) {
        const origin = IS_PRODUCTION ? new URL(SHOPIFY_URL).origin : 'http://accounts.ov.com:3001';
        window.parent.postMessage('OVID::CLOSE', origin);
      }
    };
    document.addEventListener(KEY_DOWN, onKeyDown);

    return () => {
      document.removeEventListener(KEY_DOWN, onKeyDown);
    };
  }, []);
};
export default listenToClose;
