/* eslint-disable max-len */
import { APPLE_LOGIN_URL, GOOGLE_LOGIN_URL } from '@ov-id/auth/constants';
import css from './index.module.scss';

type Props = {
  title: string;
};
const SocialSignOn: React.FC<Props> = ({ title }) => (
  <div className={css.root}>
    <h2 className={css.title}>{title}</h2>
    <p className={css.buttons}>
      <a href={GOOGLE_LOGIN_URL} className={css.button} target="_parent">
        <svg aria-hidden="true" viewBox="0 0 18 18" className={css.google}>
          <title>Google Logo</title>
          <g transform="translate(14 14)">
            <path
              d="m3.6-4.8c0-.6-.1-1.3-.2-1.8h-8.4v3.5h4.8c-.2 1.1-.8 2-1.8 2.7v2.3h3c1.7-1.6 2.6-4 2.6-6.7z"
              fill="#4285f4"
            />
            <path
              d="m-5 4c2.4 0 4.5-.8 6-2.2l-3-2.2c-.8.5-1.8.9-3 .9-2.3 0-4.3-1.6-5-3.7h-3v2.2c1.5 3.1 4.6 5 8 5z"
              fill="#34a853"
            />
            <path
              d="m-10-3.3c-.2-.6-.3-1.1-.3-1.7s.1-1.2.3-1.7v-2.3h-3c-.6 1.3-1 2.6-1 4 0 1.5.3 2.8 1 4z"
              fill="#fbbc05"
            />
            <path
              d="m-5-10.4c1.3 0 2.5.5 3.4 1.3l2.6-2.6c-1.5-1.4-3.6-2.3-6-2.3-3.4 0-6.5 1.9-8 5l3 2.3c.7-2.1 2.7-3.7 5-3.7z"
              fill="#ea4335"
            />
            <path d="m-14-14h18v18h-18z" fill="none" />
          </g>
        </svg>
        Log in with Google
      </a>
      <a href={APPLE_LOGIN_URL} className={css.button} target="_parent">
        <svg aria-hidden="true" height="44" viewBox="0 0 31 44" width="31" className={css.apple}>
          <title>Apple Logo</title>
          <g fill="none" fillRule="evenodd">
            <path d="m0 0h31v44h-31z" fill="#fff" />
            <path
              d="m15.7099491 14.8846154c.857597 0 1.9326129-.5797839 2.5727909-1.352829.5797838-.7005722 1.0025429-1.6789574 1.0025429-2.6573427 0-.1328671-.0120788-.2657342-.0362365-.3744437-.9542276.0362365-2.1017165.640178-2.7902098 1.4494596-.5435473.6160204-1.0387794 1.5823268-1.0387794 2.5727909 0 .1449459.0241577.2898919.0362365.3382072.0603942.0120789.1570248.0241577.2536554.0241577zm-3.0197075 14.6153846c1.1716465 0 1.6910362-.785124 3.1525747-.785124 1.4856961 0 1.8118245.7609663 3.1163382.7609663 1.280356 0 2.1379529-1.1837253 2.9472346-2.343293.9059122-1.3286714 1.280356-2.633185 1.3045136-2.6935792-.0845518-.0241576-2.5365543-1.0267006-2.5365543-3.841068 0-2.4399237 1.9326128-3.5390973 2.0413223-3.6236491-1.280356-1.8359822-3.2250477-1.8842975-3.7565162-1.8842975-1.4373808 0-2.6090274.8696758-3.345836.8696758-.7972028 0-1.848061-.8213605-3.0921805-.8213605-2.3674508 0-4.771138 1.9567705-4.771138 5.6528926 0 2.2949777.89383344 4.7228226 1.99300699 6.2930705.94214881 1.3286714 1.76350921 2.4157661 2.94723461 2.4157661z"
              fill="#000"
              fillRule="nonzero"
            />
          </g>
        </svg>
        Log in with Apple
      </a>
    </p>
  </div>
);

export default SocialSignOn;
