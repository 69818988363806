import { ReactElement } from 'react';
import cc from 'classcat';

import css from './index.module.scss';
import { getLayout as getBaseLayout } from '../Layout';

type Props = {
  children: ReactElement | JSX.Element[];
  modal?: boolean;
};
const LayoutUnauthenticated: React.FC<Props> = ({ children, modal = false }) => (
  <main className={cc([css.main, { [css.modal]: modal }])} id="main-content">
    {children}
  </main>
);

export const getLayout = (page, modal: boolean) =>
  getBaseLayout(<LayoutUnauthenticated modal={modal}>{page}</LayoutUnauthenticated>, modal, false);

export default LayoutUnauthenticated;
